import { combineReducers } from "@reduxjs/toolkit";
import about from "../about/store";
import blog from "../blog/store";
import buyerChecklist from "../buyer-checklist/store";
import buyerSellerFaq from "../buyer-owner-faq/store";
import forProfessionals from "../for-professionals/store";
import home from "../home/store";
import layout from "../layout/store";
import ownerChecklist from "../owner-checklist/store";
import privacyPolicy from "../privacy-policy/store";
import professionalFaq from "../professional-faq/store";
import professionalsDirectory from "../professionals-directory/store";
import termsAndConditions from "../terms-and-conditions/store";
import ourExperts from "../our-experts/store";
import ourTradies from "../our-tradies/store";

const reducer = combineReducers({
  layout,
  home,
  ourExperts,
  ourTradies,
  buyerChecklist,
  ownerChecklist,
  forProfessionals,
  about,
  professionalsDirectory,
  buyerSellerFaq,
  professionalFaq,
  privacyPolicy,
  termsAndConditions,
  blog,
});

export default reducer;
