import { RootState } from "lib/redux";
import { HYDRATE } from "next-redux-wrapper";
import { ErrorResult, isError } from "lib/axios/error";
import { IOurTradiesContent } from "models/main/our-tradies";
import { getOurTradiesContentReq } from "rest-api/main/our-tradies";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const getOurTradiesContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/our-tradies/sections/getContent", async (_, { rejectWithValue }) => {
  const result = await getOurTradiesContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectOurTradiesContent = ({ main }: RootState) => main.ourTradies.content;
export const selectOurTradiesContentHero = ({ main }: RootState) => main.ourTradies.content.hero;

const initialState: IOurTradiesContent = {
  hero: {
    title: "",
    text: "",
    image: { url: "", alternativeText: "" },
  },
  connect: {
    title: "",
    text: "",
    image: { url: "", alternativeText: "" },
  },
  howItWorks: {
    title: "",
    content: "",
    image: { url: "", alternativeText: "" },
  },
  findYourExpertSection: {
    title: "",
    items: [],
    buttons: [],
  },
  connectWithConfidence: {
    title: "",
    title_1: "",
    text_1: "",
    image_1: { url: "", alternativeText: "" },
    title_2: "",
    text_2: "",
    image_2: { url: "", alternativeText: "" },
    title_3: "",
    text_3: "",
    image_3: { url: "", alternativeText: "" },
  },
  reviews: {
    title: "",
    content: "",
    image: { url: "", alternativeText: "" },
  }
};

const contentSlice = createSlice({
  name: "main/our-tradies/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOurTradiesContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IOurTradiesContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
