import clsx from "lib/clsx";
import Link from "next/link";
import { Box } from "@mui/material";
import { useResponsiveSizes } from "components/shared/hooks";
import { Button, Section, SectionTitle } from "components/shared";

const Content = () => {
  return (
    <div className="main-container w-[422px] h-[330px] text-[0px] relative mx-auto my-0">
      <div className="flex w-[381px] h-[320px] flex-col gap-[40px] items-start flex-nowrap absolute left-[0] z-[2]">
        <div className="self-stretch shrink-0 text-[18px] font-normal leading-[26px] relative text-left z-[3] top-[5px]">
          <span className="text-[20px] font-semibold leading-[28px] text-[#02010d] relative text-left">
            1. Tell us what you need
            <br />
          </span>
          <span className="text-[18px] font-normal leading-[26px] text-[#02010d] relative text-left">
            Answer a few quick questions. It’s free and simple to say what you
            need.
          </span>
        </div>
        <div className="self-stretch shrink-0 text-[18px] font-normal leading-[26px] relative text-left z-[4] top-[5px]">
          <span className="text-[20px] font-semibold leading-[28px] text-[#02010d] relative text-left">
            2. Get Matched
            <br />
          </span>
          <span className="text-[18px] font-normal leading-[26px] text-[#02010d] relative text-left">
            Check out your personalised shortlist of top experts and tradies.
          </span>
        </div>
        <div className="self-stretch shrink-0 text-[18px] font-normal leading-[26px] relative text-left z-[5] top-[5px]">
          <span className="text-[20px] font-semibold leading-[28px] text-[#02010d] relative text-left">
            3. Choose your perfect fit
            <br />
          </span>
          <span className="text-[18px] font-normal leading-[26px] text-[#02010d] relative text-left">
            Browse their profiles and reviews before working with them.
          </span>
        </div>
      </div>
      <img src="/vertical.png" className="absolute left-[-50px] top-[10px]" />
    </div>
  );
};

const HowItWorksSection = () => {
  const title = "How it works";
  const image = "1234";

  const { getSizes } = useResponsiveSizes();

  const SectionImage = ({
    wrapperClassName,
    className,
  }: {
    wrapperClassName: string;
    className?: string;
  }) =>
    image ? (
      <Box
        className={clsx("w-full sm:w-1/2", wrapperClassName)}
        sx={{
          aspectRatio: "535 / 520",
        }}
      >
        <img className={clsx("h-full w-full", className)} src="how_it_works.png" />
      </Box>
    ) : null;

  return (
    <>
      <Section>
        <div className="container">
          <div className="sm:container-inner flex flex-col items-center gap-24 sm:flex-row">
            <div className="flex w-full flex-col gap-10 sm:w-1/2 sm:gap-12">
              <header>
                <SectionTitle className="text-pretty" variant="h2">
                  {title}
                </SectionTitle>
              </header>
              <Content />
              <Button className="w-fit py-4 sm:mt-8"
                      LinkComponent={Link}
                      href={"/auth/register"}
                      size="large"
                      variant="contained">
                Get Started
              </Button>
            </div>
            <SectionImage className="rounded-2xl" wrapperClassName="hidden sm:block" />
          </div>
        </div>
      </Section>
      <SectionImage wrapperClassName="-mb-2 block sm:hidden" />
    </>
  );
};

export default HowItWorksSection;