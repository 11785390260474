import React from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "lib/redux";
import { Section, SectionTitle } from "components/shared";
import { selectMainHomeContent } from "./store/contentSlice";

// Define the type for Card props
interface CardProps {
  title: string;
  content: string;
  button: string;
  icon: string;
}

// Card Component
const Card: React.FC<CardProps> = ({ title, content, button, icon }) => {
  return (
    <div className="main-container w-full max-w-[421px] h-auto relative mx-auto my-0 p-4 sm:p-6">
      <div className="w-full h-full bg-white rounded-[8px] shadow-md">
        <div className="flex flex-col gap-6 items-start p-6">
          {/* Content Section */}
          <div className="flex flex-col gap-4">
            {/* Title with font size 20px */}
            <span className="text-[20px] font-bold text-[#02010d]">
              {title}
            </span>
            {/* Content with font size 18px */}
            <span className="text-[18px] font-normal text-[#02010d]">
              {content}
            </span>
          </div>

          {/* Button and Icon Section */}
          <div className="flex items-center justify-between w-full">
            {/* Button */}
            <button className="px-4 py-2 text-[18px] text-[#77722e] bg-white border-2 border-[#77722e] rounded">
              {button}
            </button>

            {/* Icon */}
            <img
              src={icon}
              alt=""
              className="w-20 h-20"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// HowItWorksSection Component
const HowItWorksSection: React.FC = () => {
  const {
    howItWorksSection: {
      title,
      subtitle,
      button: { text, path },
    },
  } = useAppSelector(selectMainHomeContent);

  // Sample data for items (can be fetched or dynamically loaded)
  const items = [
    {
      title: "Learn real estate for free",
      content:
        "BrickMaster is a free and fun way to learn how to navigate the real estate market.",
      button: "Play now",
      icon: "/icon_1.png",
    },
    {
      title: "Top real estate experts",
      content:
        "From staging your home to securing your mortgage, find your perfect fit.",
      button: "Learn more",
      icon: "/icon_2.png",
    },
    {
      title: "Top tradies",
      content:
        "From roofers to cleaners, connect with skilled tradies to get the job done right.",
      button: "Learn more",
      icon: "/icon_3.png",
    },
  ];

  return (
    <Section className="-mb-0.5 bg-background-main">
      <div className="container flex flex-col gap-12 sm:gap-16">
        {/* Header */}
        <header className="flex-center mx-auto max-w-screen-xs flex-col gap-10 text-center text-light">
          <SectionTitle className="max-w-screen-xs text-pretty" variant="h2">
            {title || "What do you need?"}
          </SectionTitle>
          <Typography className="text-pretty" variant="p1">
            {subtitle}
          </Typography>
        </header>

        {/* Cards */}
        <div className="grid grid-cols-1 gap-6 text-light sm:grid-cols-2 lg:grid-cols-3 lg:gap-10">
          {items.map(({ title, content, button, icon }, index) => (
            <Card
              key={index}
              title={title}
              content={content}
              button={button}
              icon={icon}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default HowItWorksSection;