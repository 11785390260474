import { RootState } from "lib/redux";
import { HYDRATE } from "next-redux-wrapper";
import { ErrorResult, isError } from "lib/axios/error";
import { IOurExpertsContent } from "models/main/our-experts";
import { getOurExpertsContentReq } from "rest-api/main/our-experts";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const getOurExpertsContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("main/home/sections/getOurExpertsContent", async (_, { rejectWithValue }) => {
  const result = await getOurExpertsContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectOurExpertsContent = ({ main }: RootState) => main.ourExperts.content;
export const selectOurExpertsContentHero = ({ main }: RootState) => main.ourExperts.content.hero;
export const selectOurExpertsContentFindYourExpertSection = ({ main }: RootState) => main.ourExperts.content.findYourExpertSection[0];

const initialState: IOurExpertsContent = {
  hero: {},
  connect: {
    title: "",
    text: "",
    image: { url: "", alternativeText: "" },
  },
  findYourExpertSection: {
    title: "",
    items: [],
    buttons: [],
  },
  connectWithConfidence: {
    title: "",
    title_1: "",
    text_1: "",
    image_1: { url: "", alternativeText: "" },
    title_2: "",
    text_2: "",
    image_2: { url: "", alternativeText: "" },
    title_3: "",
    text_3: "",
    image_3: { url: "", alternativeText: "" },
  },
  howItWorks: {
    title: "",
    content: "",
    image: { url: "", alternativeText: "" },
  }
};

const contentSlice = createSlice({
  name: "main/our-experts/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOurExpertsContent.fulfilled, (_, action) => action.payload)
      .addCase(HYDRATE, (_, action: PayloadAction<IOurExpertsContent, typeof HYDRATE>) => action.payload);
  },
});

export default contentSlice.reducer;
