import { getError } from "lib/axios/error";
import { imagePopulate } from "models/shared";
import axiosInstance from "lib/axios/instance";
import { transformResponse } from "utils/format";
import { IOurExpertsContent } from "models/main/our-experts";

export const getOurExpertsContentReq = () => {
  return axiosInstance
    .get(`/api/our-experts-1`, {
      params: {
        fields: ["id"],
        populate: {
          hero: {
            populate: { image: imagePopulate },
          },
          findYourExpertSection: {
            populate: {
              items: { populate: { image: imagePopulate } },
              buttons: true,
            },
          },
          connectWithConfidence: {
            populate: { image_1: imagePopulate,
                        image_2: imagePopulate,
                        image_3: imagePopulate }
          },
          howItWorks: {
            populate: { image: imagePopulate },
          }
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IOurExpertsContent>)
    .catch(getError);
};
