import {
  ChecklistSection,
  FindYourExpertSection,
  WhatDoYouNeed,
  WhyUsSection,
  HowItWorksSection,
} from "components/main/home";
import {
  getHomeContent,
  selectMainHomeContentHelpSection,
  selectMainHomeContentHero,
} from "components/main/home/store/contentSlice";
import { getMainLayout } from "components/main/layout/store/layoutContentSlice";
import { HelpSection, HeroSection } from "components/main/shared/sections";
import { NewsletterSection } from "components/shared/layout/newsletter";
import { getSeo } from "components/shared/store/seoSlice";
import { throwIfError } from "lib/axios/error";
import { wrapper } from "lib/redux";
import { GetStaticProps, NextPage } from "next";

export const getStaticProps: GetStaticProps<{}> = wrapper.getStaticProps(
  ({ dispatch }) =>
    async () => {
      const { payload: mainLayoutPayload } = await dispatch(getMainLayout());
      const { payload: seoPayload } = await dispatch(getSeo("/home"));
      const { payload: contentPayload } = await dispatch(getHomeContent());
      throwIfError([mainLayoutPayload, seoPayload, contentPayload]);

      const props = {};

      return {
        props,
      };
    },
);

const Home: NextPage = () => {
  return (
    <>
      <HeroSection selector={selectMainHomeContentHero} imageAspectRatio={[550, 407]} />
      <WhatDoYouNeed />
      <HowItWorksSection />
      <FindYourExpertSection />
      <WhyUsSection />
      <ChecklistSection />
      <NewsletterSection />
      <HelpSection selector={selectMainHomeContentHelpSection} />
    </>
  );
};

export default Home;
